const routes = {
    path: 'unidades',
    name: 'Expressos_Unidades',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['EXPR-UNID-00'],
    },
    children: [
        {
            path: ':id',
            name: 'Expressos_Unidades_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['EXPR-UNID-00'],
            },
        },
        {
            path: 'inserir',
            name: 'Expressos_Unidades_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['EXPR-UNID-01'],
            },
        },
        {
            path: ':id/atualizar',
            name: 'Expressos_Unidades_Atualizar',
            component: () => import('./Atualizar.vue'),
            meta: {
                rules: ['EXPR-UNID-02'],
            },
        },
    ],
};

export default routes;
