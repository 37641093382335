import { createStore } from 'vuex';
import authModule from './auth';
import appModule from './app';
import loadingModule from './loading';
import listagensModule from './listagens';
import mensagensModulo from './mensagens'

const store = createStore({
    modules: {
        auth: authModule,
        loading: loadingModule,
        listagem: listagensModule,
        app: appModule,
        mensagens: mensagensModulo
    },
});

export default store;
