<template>
    <DataTable
        :value="data"
        :paginator="mostrarPaginacao"
        :rows="5"
        :dataKey="dataKey"
        :rowHover="true"
        v-model:filters="filters"
        :autoLayout="true"
        :globalFilterFields="globalFilterFields"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        @filter="filterCallBack($event)"
        filterDisplay="menu"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
        stripedRows
        responsiveLayout="scroll"
        stateStorage="local"
        :stateKey="salvarEstado ? stateKey : null"
        :ref="refKey"
        :exportFilename="fileName"
    >
        <template #header v-if="mostrarHeader">
            <div :class="`table-header flex flex-column md:flex-row ${alinhamentoHeader}`">
                <span class="p-input-icon-left" v-if="mostrarPesquisa">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" v-if="!pesquisaComDelay" placeholder="Pesquisar..." />
                    <input-pesquisa-delay @pesquisa="pesquisaDelay" :delay="delayPesquisa" v-else placeholder="Pesquisar..." />
                    <slot name="checkbox"> </slot>
                </span>

                <div class="flex flex-column md:flex-row">
                    <Button icon="pi pi-file-excel" label="Exportar" @click="exportCSV($event)" v-if="mostrarExportar" />
                    <Button icon="pi pi-file-excel" label="Exportar" @click="gerarPlanilhaXlsx" v-if="mostrarExportarXlsx" />
                    <slot name="botoes"> </slot>
                </div>
            </div>
        </template>
        <template #empty> Não existem registros para exibição. </template>
        <slot name="conteudo"></slot>
    </DataTable>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import InputPesquisaDelay from './InputPesquisaComTimeout.vue';

export default {
    components: { InputPesquisaDelay },
    props: {
        data: {
            type: Array,
        },

        dataKey: {
            type: String,
        },

        globalFilterFields: {
            type: Array,
        },

        mostrarPesquisa: {
            type: Boolean,
            default: () => true,
        },

        filtersProp: {
            type: Object,
            default: () => null,
        },

        filterCallBack: {
            type: Function,
            default: () => null,
        },

        stateKey: {
            type: String,
            default: () => 'dt-padrao',
        },

        refKey: {
            type: String,
            default: () => 'dt',
        },

        mostrarExportar: {
            type: Boolean,
            default: () => false,
        },

        mostrarHeader: {
            type: Boolean,
            default: () => true,
        },

        mostrarPaginacao: {
            type: Boolean,
            default: () => true,
        },
        salvarEstado: {
            type: Boolean,
            default: () => true,
        },
        pesquisaComDelay: {
            type: Boolean,
            default: () => false,
        },
        delayPesquisa: {
            type: Number,
            default: () => 500,
        },
        fileName: {
            type: String,
            default: () => 'download',
        },
        mostrarExportarXlsx: {
            type: Boolean,
            default: () => false,
        },
        gerarPlanilhaXlsx: {
            type: Function,
            default: () => null,
        },
        alinhamentoHeaderProp: {
            type: String,
            default: () => 'justify-content-between',
        },
    },

    data() {
        return {
            filtersLocal: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            filters: null,
            alinhamentoHeader: this.alinhamentoHeaderProp,
        };
    },

    created() {
        if (this.filtersProp) {
            this.filters = this.filtersProp;
        } else {
            this.filters = this.filtersLocal;
        }
    },

    watch: {
        filtersProp() {
            this.filters = this.filtersProp;
        },
    },

    methods: {
        exportCSV() {
            this.$refs[this.refKey].exportCSV();
        },
        pesquisaDelay(pesquisa) {
            this.filters.global.value = pesquisa;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}
</style>
