const routes = {
    path: 'atendimentoChamado',
    name: 'Atendimento Chamado',
    component: () => import('./AtendimentoChamado.vue'),
    // children: [
    //     {
    //         path: 'inserir',
    //         name: 'Area_Inserir',
    //         component: () => import('./AtendimentoChamado.vue'),
    //         meta: {
    //             rules: ['SOL-001'],
    //         }
    //     },
    // ],
    meta: {
        rules: ['SOL-001'],
    }
};

export default routes;
