import unidadesRotas from './unidades/rotas.js';
import atendimentosRotas from './atendimentos/rotas.js';

const routes = {
    path: 'expressos',
    name: 'Expressos',
    component: () => import('./Expressos.vue'),
    children: [unidadesRotas, atendimentosRotas],
    meta: {
        rules: [...unidadesRotas.meta.rules, ...atendimentosRotas.meta.rules],
    },
};

export default routes;
