import { routes as participantesRotas } from './participantes/rotas.js';
import { routes as certificadosRotas } from './certificados/rotas.js';

const routes = {
    path: 'brasilmais',
    name: 'BrasilMais',
    component: () => import('./BrasilMais.vue'),
    children: [
        participantesRotas, certificadosRotas
    ],
    meta: {
        rules: [
            ...participantesRotas.meta.rules,
            ...certificadosRotas.meta.rules
        ],
    },
};

export default routes;
