import controleAcesso from './controleacesso/rotas.js';
import administracao from './administracao/rotas.js';
import rdstation from './rdstation/rotas.js';
import expressos from './expressos/rotas.js';
import minerador from './minerador/rotas.js';
import gestaoEmails from './gestaoemails/rotas.js';
import mapaParceiros from './mapaparceiros/rotas.js';
import pagamentos from './pagamentos/rotas.js';
import agenciaConceito from './agenciaconceito/rotas.js';
import dashBoard from './dashboard/rotas.js';
import crm from './crm/rotas.js';
import rm from './rm/rotas.js';
import sol from './sol/rotas.js';
import checkins from './checkins/rotas.js';
import brasilmais from './brasilmais/rotas.js';
import utilidades from './utilidades/rotas.js';
import senac from './senac/rotas.js';
import nan from './nan/rotas.js';
import vendas from './vendas/rotas.js';

const routes = [
    controleAcesso,
    administracao,
    brasilmais,
    rdstation,
    expressos,
    minerador,
    gestaoEmails,
    mapaParceiros,
    pagamentos,
    agenciaConceito,
    dashBoard,
    crm,
    rm,
    checkins,
    utilidades,
    senac,
    sol,
    vendas,
    nan,
];

export default routes;
