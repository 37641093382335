import store from "@/store/store";
import router from "@/router";
import jwt_decode from "jwt-decode";

export function temAcesso(regra) {
    if (regra) {
        if (!store.getters.isAutenticado) {
            store.dispatch("tryAutoLogin");
            if (!store.getters.isAutenticado) {
                router.push("/login");
                return false;
            }
        }
        let decodedToken = jwt_decode(store.getters.getToken);
        let regrasEncoded = decodedToken.usrRegras;
        let regras = atob(regrasEncoded);
        if (typeof regra === "object") {
            if (regra.length > 0) {
                if (
                    regra.every(item => {
                        return regras.indexOf(item) === -1;
                    })
                ) {
                    return false;
                }
            }
        } else {
            if (regra) {
                if (regras.indexOf(regra) === -1) {
                    return false;
                }
            }
        }
    }
    return true;
}

export function temAcessoView(regra) {
    if (regra) {
        if (!store.getters.isAutenticado) {
            store.dispatch("tryAutoLogin");
            if (!store.getters.isAutenticado) {
                return false;
            }
        }
        let decodedToken = jwt_decode(store.getters.getToken);
        let regrasEncoded = decodedToken.usrRegras;
        let regras = atob(regrasEncoded);
        if (typeof regra === "object") {
            if (regra.length > 0) {
                if (
                    regra.every(item => {
                        return regras.indexOf(item) === -1;
                    })
                ) {
                    return false;
                }
            }
        } else {
            if (regra) {
                if (regras.indexOf(regra) === -1) {
                    return false;
                }
            }
        }
    }
    return true;
}
