import { routes as clientesRotas } from './clientes/rotas.js';
import { routes as checkoutRotas } from './checkout/rotas.js';

const routes = {
    path: 'crm',
    name: 'CRM',
    component: () => import('./CRM.vue'),
    children: [
        clientesRotas,
        checkoutRotas
    ],
    meta: {
        rules: [
            ...clientesRotas.meta.rules, ...checkoutRotas.meta.rules
        ],
    },
};

export default routes;
