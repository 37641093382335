export const routes = {
    path: 'atendimentos',
    name: 'NAN_Atendimentos',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['NAN-ATENDIMENTOS-00'],
    },
    children: [
        {
            path: ':id',
            name: 'NAN_Atendimentos_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['NAN-ATENDIMENTOS-00'],
            },
        },
    ],
};