import eventosRotas from './eventos/rotas.js';
import contasEventosRotas from './contasEventos/rotas.js';

const routes = {
    path: 'checkins',
    name: 'Checkins',
    component: () => import('./Checkins.vue'),
    children: [
        eventosRotas,
        contasEventosRotas,
    ],
    meta: {
        rules: [...eventosRotas.meta.rules, ...contasEventosRotas.meta.rules],
    },
};

export default routes;
