import menuProdutos from './produtos/menu';
import linksPagamentosMenu from './linkspagamentos/menu';

const menu = {
    label: 'Vendas',
    rules: [...linksPagamentosMenu.rules, ...menuProdutos.rules],
    items: [menuProdutos, linksPagamentosMenu],
};

export default menu;
