<template>
    <div class="grid grid-nogutter mb-2" v-if="$mq == 'xl'">
        <div class="col-fixed mr-2" :style="`width: ${size}px`">
            <div class="text-right titulo" :class="{ 'pt-2': isForm, required: obrigatorio }">
                <strong>{{ titulo }}</strong>
            </div>
        </div>
        <div class="col" v-if="negrito">
            <div class="texto-quebrado">
                <strong><slot></slot></strong>
            </div>
        </div>
        <div class="col" v-else>
            <div class="texto-quebrado">
                <slot></slot>
            </div>
        </div>
    </div>
    <div class="grid grid-nogutter mb-2" v-if="$mq != 'xl'">
        <div class="col-12 mb-1">
            <div class="titulo" :class="{ required: obrigatorio }">
                <strong>{{ titulo }}</strong>
            </div>
        </div>
        <div class="col-12">
            <div>
                <strong><slot></slot></strong>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
        },
        size: {
            type: String,
            default: '100',
        },
        negrito: {
            default: true,
        },
        isForm: {
            type: Boolean,
            default: false,
        },

        obrigatorio: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.titulo {
    color: #0f97c7;
}

.dado {
    float: right;
}

.texto-quebrado {
    max-width: 100%;
    word-wrap: break-word;
    display: block;
    white-space: normal;
}
</style>
