export const routes = {
    path: 'contas',
    name: 'Pagamentos_Contas',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['PAG-CONTAS-00'],
    },
    children: [
        {
            path: ':id',
            name: 'Pagamentos_Contas_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['PAG-CONTAS-02'],
            },
        },
        {
            path: 'inserir',
            name: 'Pagamentos_Contas_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['PAG-CONTAS-01'],
            },
        },
        {
            path: ':id/atualizar',
            name: 'Pagamentos_Contas_Atualizar',
            component: () => import('./Atualizar.vue'),
            meta: {
                rules: ['PAG-CONTAS-03'],
            },
        },
    ],
};