const routes = {
  path: 'macrossegmentoeconomico',
  name: 'MacrossegmentoEconomico',
  component: () => import('./MacrossegmentoEconomico.vue'),
  children: [
    {
      path: 'inserir',
      name: 'Macrossegmento_Economico_Inserir',
      component: () => import('./MacrossegmentoEconomicoForm.vue'),
      meta: {
        rules: ['SOL-001'],
     }
    },
    {
      path: ':id/atualizar',
      name: 'Macrossegmento_Economico_Atualizar',
      component: () => import('./MacrossegmentoEconomicoForm.vue'),
      meta: {
        rules: ['SOL-001'],
     }
    },
  ],
  meta: {
    rules: ['SOL-001'],
  }
}

export default routes;