import { routes as execucaoDiretaRotas, routesPerfilGestor as execucaoDiretaRotasPerfilGestor } from './execucaodireta/rotas.js';

const routes = {
    path: 'convenios',
    name: 'Rm_Convenios',
    component: () => import('./Convenios.vue'),
    children: [execucaoDiretaRotas, execucaoDiretaRotasPerfilGestor],
    meta: {
        rules: [...execucaoDiretaRotas.meta.rules, ...execucaoDiretaRotasPerfilGestor.meta.rules],
    },
};

export default routes;
