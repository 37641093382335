const routes = {
    path: 'emails',
    name: 'GestaoEmails_Emails',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['EMAIL-DETALHAR-00'],
    },
    children: [
        {
            path: ':id',
            name: 'GestaoEmails_Emails_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['EMAIL-DETALHAR-00'],
            },
        },
    ],
};

export default routes;
