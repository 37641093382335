import router from "@/router";
import jwt_decode from "jwt-decode";
import moment from "moment";

const authModule = {
    state() {
        return {
            token: null,
            expirationDate: null,
            usuario: {
                usuarioId: null,
                nome: null,
                email: null,
                login: null,
                aspl: null,
                regras: null
            },
            instituicao: {
                instituicaoId: null,
                sigla: null,
                nome: null
            }
        };
    },

    mutations: {
        login(state, authData) {
            let decodedToken = jwt_decode(authData.token);
            state.token = authData.token;
            state.expirationDate = authData.time;
            state.usuario.usuarioId = decodedToken.sub;
            state.usuario.nome = decodedToken.usrNome;
            state.usuario.login = decodedToken.usrLogin;
            state.usuario.email = decodedToken.email;
            state.usuario.aspl = decodedToken.usrAspl;
            state.usuario.regras = decodedToken.usrRegras;
            state.instituicao.sigla = decodedToken.usrInstituicaoSigla;
            state.instituicao.instituicaoId = decodedToken.usrInstituicaoId;
            state.instituicao.nome = decodedToken.usrInstituicaoNome;
        },

        logout(state) {
            state.token = null;
            state.usuario.usuarioId = null;
            state.usuario.nome = null;
            state.usuario.login = null;
            state.usuario.email = null;
            state.usuario.aspl = null;
            state.usuario.regras = null;
            state.instituicao.instituicaoId = null;
            state.instituicao.nome = null;
            state.instituicao.sigla = null;
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("expirationDate");
            router.push("/login");
        }
    },

    actions: {
        login({ commit, state }, authData) {
            let agora = new Date();
            let localExpiraEm = moment(agora).add(authData.expiresIn, "s");

            commit("login", {
                token: authData.accessToken,
                time: localExpiraEm
            });

            localStorage.setItem("token", authData.accessToken);
            localStorage.setItem("userId", state.usuario.usuarioId);
            localStorage.setItem("localExpirationDate", localExpiraEm);
        },

        logout({ commit }) {
            commit("logout");
        },

        tryAutoLogin({ commit }) {
            const token = localStorage.getItem("token");

            if (!token) {
                return;
            }
            const expirationDate = new Date(
                localStorage.getItem("localExpirationDate")
            );

            const now = new Date();

            if (now >= expirationDate) {
                commit("logout");
                return;
            }

            commit("login", {
                token: token,
                time: expirationDate
            });
        }
    },

    getters: {
        usuario(state) {
            return state.usuario;
        },

        instituicao(state) {
            return state.instituicao;
        },

        isAutenticado(state) {
            if (state.usuario.aspl === "True") {
                router.push("/atualizarsenha");
            }
            return state.token !== null;
        },

        getToken(state) {
            return state.token;
        },

        getExpirationDate(state) {
            return state.expirationDate;
        }
    }
};

export default authModule;
