const routes = {
    path: '/checkins/credenciamento',
    name: 'Checkins_Credenciamento',
    component: () => import('./Credenciamento.vue'),
    meta: {
        rules: ['CHECKINS-CREDENC-00'],
    },
    children: [
        // {
        //     path: 'inserir',
        //     name: 'Checkins_Eventos_Inserir',
        //     component: () => import('./Inserir.vue'),
        //     meta: {
        //         rules: ['CHECKINS-EVENTOS-01'],
        //     },
        // },
        // {
        //     path: ':id',
        //     name: 'Checkins_Eventos_Detalhar',
        //     component: () => import('./Detalhar.vue'),
        //     meta: {
        //         rules: ['CHECKINS-EVENTOS-02'],
        //     },
        //     children: [
        //         {
        //             path: 'participantes/:participanteId',
        //             name: 'Checkins_Eventos_Participantes_Detalhar',
        //             component: () => import('../participantes/Detalhar.vue'),
        //             meta: {
        //                 rules: ['CHECKINS-EVENTOS-02'],
        //             },
        //             children: [
        //                 {
        //                     path: 'atualizar',
        //                     name: 'Checkins_Eventos_Participantes_Atualizar',
        //                     component: () => import('../participantes/ParticipanteForm.vue'),
        //                     meta: {
        //                         rules: ['CHECKINS-EVENTOS-07'],
        //                     }
        //                 }
        //             ]
        //         },
        //         {
        //             path: 'checkins/:participanteId/:checkinId',
        //             name: 'Checkins_Eventos_Participantes_Checkins_Detalhar',
        //             component: () => import('../checkins/Detalhar.vue'),
        //             meta: {
        //                 rules: ['CHECKINS-EVENTOS-02'],
        //             },
        //         },
        //     ]
        // },
        // {
        //     path: ':id/atualizar',
        //     name: 'Checkins_Eventos_Atualizar',
        //     component: () => import('./Atualizar.vue'),
        //     meta: {
        //         rules: ['CHECKINS-EVENTOS-03'],
        //     },
        // },
        // {
        //     path: ':id/atendimentodetalhe/atualizar',
        //     name: 'Checkins_Eventos_AtendimentoDetalhe_Atualizar',
        //     component: () => import('./AtualizarAtendimentoDetalhe.vue'),
        //     meta: {
        //         rules: ['CHECKINS-EVENTOS-05'],
        //     },
        // }
    ],
};

export default routes;
