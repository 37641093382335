import leadsRotas from './leads/rotas.js';
import parametrosRotas from './parametros/rotas.js';

const routes = {
    path: 'rdstation',
    name: 'RdStation',
    component: () => import('./RdStation.vue'),
    children: [leadsRotas, parametrosRotas],
    meta: {
        rules: [...leadsRotas.meta.rules, ...parametrosRotas.meta.rules],
    },
};

export default routes;
