import clientesMenu from './clientes/menu';

const menu = {
    label: 'Minerador',
    icon: 'pi pi-fw pi-list',
    rules: [...clientesMenu.rules],
    items: [clientesMenu],
};

export default menu;
