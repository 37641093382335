import parceiroMenu from './pessoas/menuparceiro';
import interlocutorMenu from './interlocutores/menu';
import parceriaMenu from './parceria/menu';
import exportXls from './exportacaoXls/menu';
import testemunhaMenu from './testemunhas/menu';

const biRelatorioMenuItem = {
    label: 'BI Gerador de Relatórios',
    icon: 'pi pi-fw pi-book',
    url: 'https://analisededados.sebrae.com.br/sense/app/ad4dab95-975f-4520-b7a9-cc791ecf6283/sheet/e8060478-db2a-42d0-9b19-56d1a1d5a37a/state/analysis',
    target: '_blank',
};

const documentoMenuItem = {
    label: 'Documento',
    icon: 'pi pi-fw pi-external-link',
    url: 'https://sebraepr.sharepoint.com/:f:/r/sites/xerisebraepe/Documentos%20Compartilhados/Unidades/Superintend%C3%AAncia/UAI/PAR%C3%87A?csf=1&web=1&e=RMm97a',
    target: '_blank',
};

const menu = {
    label: 'Parça',
    icon: 'pi pi-fw pi-id-card',
    rules: [...exportXls.rules, ...parceiroMenu.rules, ...interlocutorMenu.rules, ...parceriaMenu.rules, ...testemunhaMenu.rules],
    items: [exportXls, interlocutorMenu, testemunhaMenu, parceiroMenu, parceriaMenu, biRelatorioMenuItem, documentoMenuItem],
};

export default menu;