const routes = {
    path: 'usuarios',
    name: 'Usuarios Sol',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['SOL-001'],
    },
    children: [
        {
            path: ':id',
            name: 'Usuarios_Sol_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['SOL-001'],
            },
        },
        {
            path: 'inserir',
            name: 'Usuarios_Sol_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['SOL-001'],
            },
        },
        {
            path: ':id/atualizar',
            name: 'Usuarios_Sol_Atualizar',
            component: () => import('./Atualizar.vue'),
            meta: {
                rules: ['SOL-001'],
            },
        },
    ],
};

export default routes;
