const routes = {
    path: 'area',
    name: 'Area',
    component: () => import('./Area.vue'),
    children: [
        {
            path: 'inserir',
            name: 'Area_Inserir',
            component: () => import('./AreaForm.vue'),
            meta: {
                rules: ['SOL-001'],
            }
        },
        {
            path: ':id/atualizar',
            name: 'Area_Atualizar',
            component: () => import('./AreaForm.vue'),
            meta: {
                rules: ['SOL-001'],
            }
        },
    ],
    meta: {
        rules: ['SOL-001'],
    }
};

export default routes;
