const routes = {
  path: 'complexidademetodologia',
  name: 'ComplexidadeMetodologia',
  component: () => import('./ComplexidadeMetodologia.vue'),
  children: [
    {
      path: 'inserir',
      name: 'Complexidade_Metodologia_Inserir',
      component: () => import('./ComplexidadeMetodologiaForm.vue'),
      meta: {
        rules: ['SOL-001'],
      }
    },
    {
      path: ':id/atualizar',
      name: 'Complexidade_Metodologia_Atualizar',
      component: () => import('./ComplexidadeMetodologiaForm.vue'),
      meta: {
        rules: ['SOL-001'],
      }
    },
  ],
  meta: {
    rules: ['SOL-001'],
  }
}

export default routes;