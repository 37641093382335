export const routes = {
    path: 'carrinhos',
    name: 'CRM_Checkout_Carrinhos',
    component: () => import('./Carrinhos.vue'),
    meta: {
        rules: ['CRM-CHECKOUT-01', 'CRM-CHECKOUT-02'],
    },
    children: [
        {
            path: ':id/pagamento',
            name: 'CRM_Checkout_Carrinhos_Pagamento',
            component: () => import('./Pagamento.vue'),
            meta: {
                rules: [],
            },
        },
        {
            path: 'listagem',
            name: 'CRM_Checkout_Carrinhos_Listagem',
            component: () => import('./Listagem.vue'),
            meta: {
                rules: ['CRM-CHECKOUT-01'],
            },
        },
        {
            path: ':id',
            name: 'CRM_Checkout_Carrinhos_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['CRM-CHECKOUT-02'],
            },
        },
    ]
};
