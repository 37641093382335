import usuariosRotas from './usuarios/rotas.js';

const routes = {
    path: 'senac',
    name: 'Senac',
    component: () => import('./Senac.vue'),
    children: [
        usuariosRotas
    ],
    meta: {
        rules: [
            ...usuariosRotas.meta.rules,
        ],
    },
};

export default routes;
