import emailsMenu from './emails/menu';

const menu = {
    label: 'Gestão de Emails',
    icon: 'pi pi-fw pi-id-card',
    rules: [...emailsMenu.rules],
    items: [emailsMenu],
};

export default menu;
