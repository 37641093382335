const produtosRoutes = {
    path: '/vendas/cadastroprodutos',
    name: 'Inserir_Produtos',
    component: () => import('./CheckoutVendas.vue'),
    meta: {
        rules: ['CHECKOUTS-PAGAMENTOS-01'],
    },
};

export default produtosRoutes;
