import emailsRotas from './emails/rotas.js';

const routes = {
    path: 'gestaoemails',
    name: 'GestaoEmails',
    component: () => import('./GestaoEmails.vue'),
    children: [ emailsRotas],
    meta: {
        rules: [...emailsRotas.meta.rules],
    },
};

export default routes;
