import { routes as checkInRotas } from './checkins/rotas.js';

const routes = {
    path: 'agenciaconceito',
    name: 'AgenciaConceito',
    component: () => import('./AgenciaConceito.vue'),
    children: [
        checkInRotas,
    ],
    meta: {
        rules: [
            ...checkInRotas.meta.rules,
        ],
    },
};

export default routes;
