import {
    menuFinanceiro as conveniosExecucaoDiretaMenuPerfilFinanceiro,
    menuGestor as conveniosExecucaoDiretaMenuPerfilGestor,
} from './convenios/execucaodireta/menu';
import parametrosMenu from './parametros/menu';

const rmMenu = {
    label: 'RM',
    icon: 'pi pi-fw pi-briefcase',
    rules: [...conveniosExecucaoDiretaMenuPerfilFinanceiro.rules, ...parametrosMenu.rules, ...conveniosExecucaoDiretaMenuPerfilGestor.rules],
    items: [conveniosExecucaoDiretaMenuPerfilFinanceiro, parametrosMenu, conveniosExecucaoDiretaMenuPerfilGestor],
};

export default rmMenu;
