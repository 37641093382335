const routes = {
    path: 'parametros',
    name: 'RdStation_Parametros',
    component: () => import('./Detalhar.vue'),
    meta: {
        rules: ['RD-PARAMETROS-00'],
    },
    children: [
        {
            path: 'atualizar',
            name: 'RdStation_Parametros_Atualizar',
            component: () => import('./Formulario.vue'),
            meta: {
                rules: ['RD-PARAMETROS-01'],
            },
        },
    ],
};

export default routes;
