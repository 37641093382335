<template>
    <transition-group name="p-messages" tag="div">
        <Message
            :severity="tipo"
            v-if="$store.getters.mensagens.length > 0 && !fromBus"
            @close="limpar()"
        >
            <ul>
                <li v-for="item in $store.getters.mensagens" :key="item">{{ item }}</li>
            </ul>
        </Message>
        <Message :severity="tipo" v-if="itensTemp.length > 0 && !fromBus" @close="limpar()">
            <ul>
                <li v-for="item in itensTemp" :key="item">{{ item }}</li>
            </ul>
        </Message>
    </transition-group>
</template>

<script>
export default {
    props: {
        itens: {
            type: Array,
            default: () => [],
        },

        tipo: {
            type: String,
            default: () => 'warn',
        },

        fromBus: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            itensTemp: [],
        };
    },

    computed: {
        show() {
            return this.itensTemp.length > 0;
        },
    },

    watch: {
        itens() {
            this.itensTemp = [...this.itens];
        },
    },

    created() {
        this.itensTemp = [...this.itens];
    },

    methods: {
        limpar() {
            if (this.fromBus) {
                this.$store.dispatch('limparMensagens');
            } else {
                this.itensTemp = [];
            }
        },
    },
};
</script>
