import RotaParceiro from './pessoas/rotaParceiro.js';
import RotaInterlocutor from './interlocutores/rotas.js';
import RotaParceria from './parceria/rotasParceria.js';
import RotaExportarXls from './exportacaoXls/rotas.js';
import RotaTestemunha from './testemunhas/rotas.js';

const routes = {
    path: 'mapaparceiros',
    name: 'MapaParceiros',
    component: () => import('./MapaParceiros.vue'),
    children: [RotaParceiro, RotaInterlocutor, RotaParceria, RotaExportarXls, RotaTestemunha],
    meta: {
        rules: [...RotaParceiro.meta.rules, ...RotaInterlocutor.meta.rules, ...RotaParceria.meta.rules, ...RotaExportarXls.meta.rules,
            ...RotaTestemunha.meta.rules],
    },
    
    
};

export default routes;

