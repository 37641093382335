export const routes = {
    path: 'pix',
    name: 'Pagamentos_Pix',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['PAG-PIX-00'],
    },
    children: [
        {
            path: ':id',
            name: 'Pagamentos_Pix_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['PAG-PIX-01'],
            },
        }
    ],
};