import { menu as lerQRCodeMenu } from './checkins/qrcode/menuLer';
import { menu as checkInsMenu } from './checkins/menuListar';

const agenciaConceitoMenu = {
    label: 'Agência Conceito',
    icon: 'pi pi-fw pi-building',
    rules: [
        ...lerQRCodeMenu.rules,
        ...checkInsMenu.rules,
    ],
    items: [
        lerQRCodeMenu,
        checkInsMenu,
    ],
};

export default agenciaConceitoMenu
