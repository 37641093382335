const routes = {
    path: 'instrumentos',
    name: 'Instrumentos',
    component: () => import('../tiposervico/TipoServico.vue'),
    children: [
        {
            path: 'inserir',
            name: 'Instrumento_Inserir',
            component: () => import('../tiposervico/TipoServicoForm.vue'),
            meta: {
                rules: ['SOL-001'],
            },
        },
        {
            path: ':id/atualizar',
            name: 'Instrumento_Atualizar',
            component: () => import('../tiposervico/TipoServicoForm.vue'),
            meta: {
                rules: ['SOL-001'],
            },
        },
    ],
    meta: {
        rules: ['SOL-001'],
    },
};

export default routes;
