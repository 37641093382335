const routes = {
    path: 'parametros',
    name: 'Convenios_Parametros',
    component: () => import('./Detalhar.vue'),
    meta: {
        rules: ['CONVENIOS-PARAMETROS-00'],
    },
    children: [
        {
            path: 'atualizar',
            name: 'Convenios_Parametros_Atualizar',
            component: () => import('./Formulario.vue'),
            meta: {
                rules: ['CONVENIOS-PARAMETROS-01'],
            },
        },
    ],
};

export default routes;
