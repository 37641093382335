const routes = {
  path: 'subareas',
  name: 'SubArea',
  component: () => import('./SubArea.vue'),
  children: [
      {
          path: 'inserir',
          name: 'Sub_Area_Inserir',
          component: () => import('./SubAreaForm.vue'),
          meta: {
            rules: ['SOL-001'],
        }
      },
      {
          path: ':id/atualizar',
          name: 'Sub_Area_Atualizar',
          component: () => import('./SubAreaForm.vue'),
          meta: {
            rules: ['SOL-001'],
        }
      },
  ],
  meta: {
    rules: ['SOL-001'],
}
};

export default routes;
