import eventosMenu from './eventos/menu';
import contasEventosMenu from './contasEventos/menu';
import credenciamentoMenu from './credenciamento/menu';
import feiraMenu from './feiraempreendedor/menu';

const menu = {
    label: 'Webster',
    icon: 'pi pi-fw pi-id-card',
    rules: [...eventosMenu.rules, ...contasEventosMenu.rules, ...credenciamentoMenu.rules, ...feiraMenu.rules],
    items: [eventosMenu, contasEventosMenu, credenciamentoMenu, feiraMenu],
};

export default menu;
