const routes = {
    path: 'contas-eventos',
    name: 'Checkins_ContasEventos',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['CHECKINS-CONTAS-EVENTOS-00'],
    },
    children: [
        {
            path: 'inserir',
            name: 'Checkins_ContasEventos_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['CHECKINS-CONTAS-EVENTOS-01'],
            },
        },
        {
            path: ':id/atualizar',
            name: 'Checkins_ContasEventos_Atualizar',
            component: () => import('./Atualizar.vue'),
            meta: {
                rules: ['CHECKINS-CONTAS-EVENTOS-03']
            },
        }
    ],
};

export default routes;