const routes = {
    path: 'clientes',
    name: 'Minerador_Clientes',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['MINERADOR-CLIENTES-00'],
    },
    children: [
        {
            path: ':id',
            name: 'Minerador_Clientes_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['MINERADOR-CLIENTES-00'],
            },
        },
        // {
        //     path: 'inserir',
        //     name: 'Associados_Inserir',
        //     component: () => import('./Inserir.vue'),
        //     meta: {
        //         rules: ['ASSOCIADOS-01'],
        //     },
        // },
        // {
        //     path: ':id/atualizar',
        //     name: 'Associados_Atualizar',
        //     component: () => import('./Atualizar.vue'),
        //     meta: {
        //         rules: ['ASSOCIADOS-03'],
        //     },
        // },
    ],
};

export default routes;
