const routes = [
    {
        path: '/checkins/feiraempreendedor',
        name: 'FeiraEmpreendedor',
        component: () => import('./Form.vue'),

    },
    {
        path: '/checkins/feiraempreendedor/atendimento',
        name: 'FeiraEmpreendedorAtendimento',
        component: () => import('./Form.vue'),
        meta: {
            rules: ['CHECKINS-INSCRICOES-02'],
        },

    },
    {
        path: '/checkins/feiraempreendedor/totem',
        name: 'FeiraEmpreendedorTotem',
        component: () => import('./FormTotem.vue'),
    }
];

export default routes;
