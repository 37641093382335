const routes = {
    path: 'agrupador',
    name: 'Agrupador',
    component: () => import('./Agrupador.vue'),
    children: [
        {
            path: 'inserir',
            name: 'Agrupador_Inserir',
            component: () => import('./AgrupadorForm.vue'),
            meta: {
                rules: ['SOL-001'],
            }
        },
        {
            path: ':id/atualizar',
            name: 'Agrupador_Atualizar',
            component: () => import('./AgrupadorForm.vue'),
            meta: {
                rules: ['SOL-001'],
            }
        },
    ],
    meta: {
        rules: ['SOL-001'],
    }
};

export default routes;
