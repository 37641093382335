export const routes = {
    path: 'qrcode',
    name: 'AgenciaConceito_CheckIns_QRCode',
    component: () => import('./CheckInQRCode.vue'),
    meta: {
        rules: ['AGECON-CHECKIN-02'],
    },
    children: [
        {
            path: 'ler',
            name: 'AgenciaConceito_CheckIns_QRCode_Ler',
            component: () => import('./LerQRCode.vue'),
            meta: {
                rules: ['AGECON-CHECKIN-02'],
            },
        },
    ]
};
