const routes = {
  path: 'relatorio',
  name: 'relatorio',
  component: () => import('./Relatorio.vue'),
  meta: {
    rules: ['SOL-001'],
}
};

export default routes;
