const routes = {
    path: 'personas',
    name: 'Personas',
    component: () => import('./Persona.vue'),
    children: [
        {
            path: 'inserir',
            name: 'Persona_Inserir',
            component: () => import('./PersonaForm.vue'),
            meta: {
                rules: ['SOL-001'],
            }
        },
        {
            path: ':id/atualizar',
            name: 'Persona_Atualizar',
            component: () => import('./PersonaForm.vue'),
            meta: {
                rules: ['SOL-001'],
            }
        },
    ],
    meta: {
        rules: ['SOL-001'],
    }
};

export default routes;
