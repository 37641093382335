const routes = {
    path: 'usuarios',
    name: 'Senac_Usuarios',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['SENAC-USUARIOS-00'],
    },
    children: [
        {
            path: ':id',
            name: 'Senac_Usuarios_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['SENAC-USUARIOS-02'],
            },
        },
        {
            path: 'inserir',
            name: 'Senac_Usuarios_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['SENAC-USUARIOS-01'],
            },
        },
    ],
};

export default routes;
