import clientesRotas from './clientes/rotas.js';

const routes = {
    path: 'minerador',
    name: 'Minerador',
    component: () => import('./Minerador.vue'),
    children: [clientesRotas],
    meta: {
        rules: [...clientesRotas.meta.rules],
    },
};

export default routes;
