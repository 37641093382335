<template>
    <div class="text-color mr-2 flex align-items-center text-center">
        <countdown :time="tempo" format="HH:mm:ss" @finish="endCallBack()">
            <template #="{ resolved }">
                <span>
                    <small>Tempo de Sessão</small>
                </span>
                <br />
                <span>{{ resolved.HH }}</span> : <span>{{ resolved.mm }}</span> :
                <span>{{ resolved.ss }}</span>
            </template>
        </countdown>
    </div>
</template>

<script>
import Countdown from 'vue3-countdown';

export default {
    name: 'TempoSessao',

    data() {
        return {
            tempo: 60 * 1000,
        };
    },

    methods: {
        endCallBack() {
            this.$store.dispatch('logout');
        },
    },

    created() {
        let x = this.$moment(this.$store.getters.getExpirationDate);
        let y = this.$moment();
        this.tempo = this.$moment.duration(x.diff(y)).asMilliseconds();
    },
    components: {
        Countdown,
    },
};
</script>

<style scoped>
.text-color {
    color: white;
}
</style>
