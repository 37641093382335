<template>
    <InputText v-model="data" @input="intervalo" />
</template>

<script>
export default {
    props: {
        delay: {
            type: Number,
        }
    },
    data() {
        return {
            data: null,
        };
    },

    methods: {
        
        intervalo() {
            clearTimeout(this.searchTimeout);

            this.searchTimeout = setTimeout(() => {
                    this.$emit('pesquisa', this.data);
            }, this.delay);
        },

    },
    
};
</script>
