<template>
    <transition-group name="p-messages" tag="div">
        <Message
            :severity="tipo"
            v-if="$store.getters.erros.length > 0 && fromBus"
            @close="limpar()"
        >
            <ul>
                <li v-for="erro in $store.getters.erros" :key="erro">{{ erro }}</li>
            </ul>
        </Message>
        <Message :severity="tipo" v-if="errosTemp.length > 0 && !fromBus" @close="limpar()">
            <ul>
                <li v-for="erro in errosTemp" :key="erro">{{ erro }}</li>
            </ul>
        </Message>
    </transition-group>
</template>

<script>
export default {
    props: {
        erros: {
            type: Array,
            default: () => [],
        },

        tipo: {
            type: String,
            default: () => 'error',
        },

        fromBus: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            errosTemp: [],
        };
    },

    computed: {
        show() {
            return this.errosTemp.length > 0;
        },
    },

    watch: {
        erros() {
            this.errosTemp = [...this.erros];
        },
    },

    created() {
        this.errosTemp = [...this.erros];
    },

    methods: {
        limpar() {
            if (this.fromBus) {
                this.$store.dispatch('limparErros');
            } else {
                this.errosTemp = [];
            }
        },
    },
};
</script>
