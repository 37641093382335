const routes = {
    path: 'execucaodireta',
    name: 'Rm_Convenios_ExecucaoDireta',
    component: () => import('./Listagem.vue'),
    children: [
        {
            path: ':id',
            name: 'Rm_Convenios_ExecucaoDireta_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['RM-CONV-EXEC-DIRETA-01'],
            },
        },
    ],
    meta: {
        rules: ['RM-CONV-EXEC-DIRETA-00', 'RM-CONV-EXEC-DIRETA-01'],
    },
};

const routesPerfilGestor = {
    path: 'execucaodireta/gestor',
    name: 'Rm_Convenios_ExecucaoDireta_gestor',
    component: () => import('./Listagem.vue'),
    children: [
        {
            path: ':id',
            name: 'Rm_Convenios_ExecucaoDireta_Detalhar_Gestor',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['RM-CONV-EXEC-DIRETA-GESTOR'],
            },
        },
    ],
    meta: {
        rules: ['RM-CONV-EXEC-DIRETA-GESTOR'],
    },
};

export { routes, routesPerfilGestor };
