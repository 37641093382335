const routes = {
    path: 'politicaprecos',
    name: 'PoliticaPrecos',
    component: () => import('./PoliticaPrecos.vue'),
    children: [
        {
            path: 'inserir',
            name: 'Politica_Precos_Inserir',
            component: () => import('./PoliticaPrecosForm.vue'),
            meta: {
                rules: ['SOL-001'],
            }
        },
        {
            path: ':id/atualizar',
            name: 'Politica_Precos_Atualizar',
            component: () => import('./PoliticaPrecosForm.vue'),
            meta: {
                rules: ['SOL-001'],
            }
        },
    ],
    meta: {
        rules: ['SOL-001'],
    }
};

export default routes;
