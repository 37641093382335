import axios from 'axios';
import { app } from '../main';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGtag from 'vue-gtag-next';

const appModule = {
    state() {
        return {
            menuVisible: true,
        };
    },

    mutations: {
        carregar() {
            axios.get('app/recaptcha.txt').then((res) => {
                app.use(VueReCaptcha, {
                    siteKey: res.data.trim(),
                    loaderOptions: {
                        useRecaptchaNet: true,
                        autoHideBadge: true,
                    },
                });
            });

            axios.get('app/analytics.txt').then((res) => {
                app.use(VueGtag, {
                    property: {
                        id: res.data,
                    },
                });
            });
        },

        trocarMenu(state) {
            state.menuVisible = !state.menuVisible;
        },
    },

    actions: {
        async carregarConfigApp({ commit }) {
            commit('carregar');
        },

        async verificarVersao() {
            await axios
                .get('app/versao.txt', {
                    headers: {
                        'Cache-Control': 'no-cache',
                    },
                })
                .then((res) => {
                    let versaoLida = res.data.replace(/[^a-zA-Z0-9.-]/g, '');
                    if (process.env.VUE_APP_VERSION != versaoLida) {
                        window.location.reload();
                    }
                });
        },

        trocarMenu({ commit }) {
            commit('trocarMenu');
        },
    },

    getters: {
        menuVisible(state) {
            return state.menuVisible;
        },
    },
};

export default appModule;
