const mensagensModule = {
    state() {
        return {
            mensagens: [],
            erros: [],
            requestError: null
        };
    },

    mutations: {
        setMensagens(state, mensagens) {
            state.mensagens = [...mensagens];
        },

        limparMensagens(state) {
            state.mensagens = [];
        },

        setErros(state, erros) {
            state.erros = [...erros];
        },

        limparErros(state) {
            state.erros = [];
        },

        setRequestError(state, error) {
            state.requestError = error
        },

        limparRequestError(state) {
            state.requestError = null
        }

    },

    actions: {
        setMensagens({ commit }, mensagens) {
            commit('setMensagens', mensagens);
        },

        limparMensagens({ commit }) {
            commit('limparMensagens');
        },

        setErros({ commit }, erros) {
            commit('setErros', erros);
        },

        limparErros({ commit }) {
            commit('limparErros');
        },

        setRequestError({ commit }, error) {
            commit('resetRequest');
            commit('setRequestError', error);
        },

        limparRequestError({ commit }) {
            commit('limparRequestError');
        },
    },

    getters: {
        mensagens(state) {
            return state.mensagens;
        },

        erros(state) {
            return state.erros;
        },

        requestError(state) {
            return state.requestError
        }
    },
};

export default mensagensModule;
