const routes = {
  path: 'setoreconomico',
  name: 'SetorEconomico',
  component: () => import('./SetorEconomico.vue'),
  children: [
    {
      path: 'inserir',
      name: 'Setor_Economico_Inserir',
      component: () => import('./SetorEconomicoForm.vue'),
      meta: {
        rules: ['SOL-001'],
    }
    },
    {
      path: ':id/atualizar',
      name: 'Setor_Economico_Atualizar',
      component: () => import('./SetorEconomicoForm.vue'),
      meta: {
        rules: ['SOL-001'],
    }
    },
  ],
  meta: {
    rules: ['SOL-001'],
}
}

export default routes;