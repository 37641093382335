export const routes = {
    path: 'clientes',
    name: 'CRM_Clientes',
    component: () => import('./Clientes.vue'),
    children: [
        {
            path: 'historico',
            name: 'CRM_Clientes_Historico',
            component: () => import('./historico/Historico.vue'),
            meta: {
                rules: ['CRM-CLIENTES-HISTORICO'],
            },
        },
        {
            path: 'eventos',
            name: 'CRM_Clientes_Eventos',
            component: () => import('./eventos/Eventos.vue'),
            meta: {
                rules: ['CRM-CLIENTES-EVENTOS'],
            },
        }
    ],
    meta: {
        rules: [
            'CRM-CLIENTES-HISTORICO', 'CRM-CLIENTES-EVENTOS'
        ],
    },
};
