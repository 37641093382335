<template>
    <label :for="id" :class="getLabelClass()">{{ label }}</label>
    <div class='p-inputgroup'>
        <Calendar :id='id' :class='getInputClass()' :disabled='desabilita' :modelValue='modelValue' :showIcon='showIcon'
                  date-format='dd/mm/yy' v-bind='$attrs' @blur="$emit('blur')" @input='updateValue'
                  @date-select='updateValueDate' />
    </div>
    <small class="p-error" v-if="temErro">{{ this.mensagemErro || '&nbsp;'
    }}</small>
</template>
<script>
export default {
    inheritAttrs: false,
    props: {
        label: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        desabilita: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: [String, Date],
            default: '',
        },
        temErro: {
            type: Boolean,
            default: false,
        },
        mensagemErro: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            id: `input-${Math.random().toString(36).substring(2, 9)}`,
        };
    },
    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        updateValueDate(date) {
            this.$emit('update:modelValue', date);
        },
        getInputClass() {
            return {
                'p-invalid': this.temErro,
            };
        },
        getLabelClass() {
            return {
                'error-field-label': this.temErro,
                'required': this.required,
            };
        },
    },
}
</script>