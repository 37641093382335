import consultoriasRotas from './consultorias/rotas.js';

const routes = {
    path: 'utilidades',
    name: 'Utilidades',
    component: () => import('./Utilidades.vue'),
    children: [
        consultoriasRotas
    ],
    meta: {
        rules: [
            ...consultoriasRotas.meta.rules,
        ],
    },
};

export default routes;
