import conveniosRotas from './convenios/rotas.js';
import parametrosRotas from './parametros/rotas.js';

const routes = {
    path: 'rm',
    name: 'Rm',
    component: () => import('./Rm.vue'),
    children: [
        conveniosRotas, parametrosRotas
    ],
    meta: {
        rules: [
            ...conveniosRotas.meta.rules, ...parametrosRotas.meta.rules
        ],
    },
};

export default routes;
