import { createApp, reactive } from 'vue';
import router from './router';
import App from './AppMain.vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FullCalendar from 'primevue/fullcalendar';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';

import CodeHighlight from './AppCodeHighlight';
import BlockViewer from './BlockViewer';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './App.scss';
import './assets/demo/flags/flags.css';

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import store from './store/store';

// Moment Library
import moment from 'moment';
//Vue Mediaquery
import VueMq from 'vue3-mq';

//SweetAlert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

//Numeral
import numeral from 'numeral';
// Componentes customizados
import ErrosBox from './shared/components/ErrosBoxComponent';
import MsgBox from './shared/components/MsgBoxComponent';
import LoadingBlockUI from './shared/components/LoadingBlockUIComponent';
import BtnRefresh from './shared/components/BtnRefreshComponent';
import Detalhe from './shared/components/DetalheComponent';
import Status from './shared/components/StatusComponent';
import BtnVoltar from './shared/components/BtnVoltarComponent';
import Tabela from './shared/components/TabelaComponent';
import Painel from './shared/components/PainelComponent';
import BInput from './shared/components/inputs/BInput';
import BInputNumeric from './shared/components/inputs/BInputNumeric.vue';
import BInputMask from './shared/components/inputs/BInputMask';
import BDropdown from './shared/components/inputs/BDropdown';
import BCalendar from './shared/components/inputs/BCalendar';

// Bibliotecas customizados
import { temAcesso, temAcessoView } from '@/shared/auth/temAcesso';
import masker from './shared/libs/mask/masker';
import download from './shared/libs/download';
import cpfMasker from './shared/libs/mask/cpfMasker';
import cnpjMasker from './shared/libs/mask/cnpjMasker';
import cpfCnpjMasker from './shared/libs/mask/cpfCnpjMasker';
import telefoneMasker from './shared/libs/mask/telefoneMasker';
import cepMasker from './shared/libs/mask/cepMasker';
import dateFormat from './shared/libs/dateHelper';
import { validarCPF } from './shared/libs/cpf';
import { validarCNPJ } from './shared/libs/cnpj';
import './shared/libs/textoHelpers';
import isValidEmail from './shared/libs/is-valid-email';

library.add(fas);

moment.locale('pt-BR');

numeral.register('locale', 'pt-BR', {
    delimiters: {
        thousands: '.',
        decimal: ',',
    },
    currency: {
        symbol: 'R$ ',
    },
});
numeral.locale('pt-BR');

export const app = createApp(App);

app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });
app.config.globalProperties.$primevue = reactive({ ripple: true });
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$temAcesso = temAcesso;
app.config.globalProperties.$temAcessoView = temAcessoView;
app.config.globalProperties.$masker = masker;
app.config.globalProperties.$toCpf = cpfMasker;
app.config.globalProperties.$toCnpj = cnpjMasker;
app.config.globalProperties.$toCpfCnpj = cpfCnpjMasker;
app.config.globalProperties.$toTel = telefoneMasker;
app.config.globalProperties.$toCep = cepMasker;
app.config.globalProperties.$validarCPF = validarCPF;
app.config.globalProperties.$validarCNPJ = validarCNPJ;
app.config.globalProperties.$validarEmail = isValidEmail;
app.config.globalProperties.$dateFormat = dateFormat;
app.config.globalProperties.$download = download;
app.config.globalProperties.$numeral = numeral;

app.use(PrimeVue, {
    locale: {
        startsWith: 'Começa com',
        contains: 'Contem',
        notContains: 'Não contem',
        endsWith: 'Termina com',
        equals: 'Igual',
        notEquals: 'Não é igual',
        noFilter: 'Sem filtro',
        lt: 'Menor que',
        lte: 'Menor que ou igual a',
        gt: 'Maior que',
        gte: 'Maior que ou igua a',
        dateIs: 'Date é',
        dateIsNot: 'Data não é',
        dateBefore: 'Data é antes',
        dateAfter: 'Data é depois',
        clear: 'Limpar',
        apply: 'Aplicar',
        matchAll: 'Combinar tudo',
        matchAny: 'Corresponder a qualquer',
        addRule: 'Adicionar regra',
        removeRule: 'Remover regra',
        accept: 'Sim',
        reject: 'Não',
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        today: 'Hoje',
        weekHeader: 'Semana',
        firstDayOfWeek: 0,
        dateFormat: 'dd/mm/yy',
    },
    ripple: true,
});

app.use(ConfirmationService);
app.use(ToastService);
app.use(router);

app.use(store);

app.use(VueMq, {
    breakpoints: {
        sm: 576,
        md: 768,
        lg: 992,
        xl: Infinity,
    },
});

app.use(VueSweetalert2);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Editor', Editor);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('FullCalendar', FullCalendar);
app.component('Galleria', Galleria);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SpeedDial', SpeedDial);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);

app.component('BlockViewer', BlockViewer);

app.component('ErrosBox', ErrosBox);
app.component('MsgBox', MsgBox);
app.component('LoadingBlockUi', LoadingBlockUI);
app.component('BtnRefresh', BtnRefresh);
app.component('Detalhe', Detalhe);
app.component('Status', Status);
app.component('BtnVoltar', BtnVoltar);
app.component('Tabela', Tabela);
app.component('Painel', Painel);
app.component('BInput', BInput);
app.component('BInputNumeric', BInputNumeric);
app.component('BInputMask', BInputMask);
app.component('BDropdown', BDropdown);
app.component('BCalendar', BCalendar);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('font-awesome-layers', FontAwesomeLayers);
app.component('font-awesome-layers-text', FontAwesomeLayersText);

app.mount('#app');
