const centralChamados = {
    path: '/sol/chamado/centralChamados',
    name: 'sol_centralChamados',
    component: () => import('./CentralChamados.vue'),
};

const curadoria = {
    path: '/sol/chamado/curadoria',
    name: 'sol_curadoria',
    component: () => import('./SolicitacaoCuradoria.vue'),
};

const consultoria = {
    path: '/sol/chamado/consultoria',
    name: 'sol_consultoria',
    component: () => import('./SolicitacaoConsultoria.vue'),
};

const produtoReal = {
    path: '/sol/chamado/produtoReal',
    name: 'sol_produtoReal',
    component: () => import('./SolicitacaoProdutoReal.vue'),
};

const rotasChamado = [
    centralChamados,
    curadoria,
    consultoria,
    produtoReal
]

const rotasLiberadas = [
    ...rotasChamado.map(route => route.path)
]

export {
    rotasChamado,
    rotasLiberadas
};
