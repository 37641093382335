import unidadesMenu from './unidades/menu';
import atendimentosMenu from './atendimentos/menu';

const menu = {
    label: 'Expressos',
    icon: 'pi pi-fw pi-id-card',
    rules: [...unidadesMenu.rules, ...atendimentosMenu.rules],
    items: [unidadesMenu, atendimentosMenu],
};

export default menu;
