import { menu as atendimentosMenu } from './atendimentos/menu';
import { menu as parceiropjMenu } from './parceiropj/menu';
// import { menu as parceiropfMenu } from './parceiropf/menu';
import { menuAlterarPf as menuAlterarPf } from './parceiropf/menuAlterarPf';

export const nanMenu = {
    label: 'NAN',
    icon: 'pi pi-book',
    rules: [...atendimentosMenu.rules, ...parceiropjMenu.rules,  ...menuAlterarPf.rules
        // ...parceiropfMenu.rules,
    ],
    items: [atendimentosMenu, 
        {
            label: 'Parceiros',
            icon: 'pi pi-pencil',
            items: [
                {
                    label: 'Parceiros PJ',
                    icon: 'pi pi-pencil',
                    items: [
                        parceiropjMenu
                    ],
                },
                {
                    label: 'Parceiros PF',
                    icon: 'pi pi-pencil',
                    items: [
                        // parceiropfMenu,
                        menuAlterarPf
                    ],
                },
            ],
        },
    ],
};
