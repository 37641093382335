export function validarCNPJ(cnpj) {
    if (
        cnpj == '00000000000000' ||
        cnpj == '11111111111111' ||
        cnpj == '22222222222222' ||
        cnpj == '33333333333333' ||
        cnpj == '44444444444444' ||
        cnpj == '55555555555555' ||
        cnpj == '66666666666666' ||
        cnpj == '77777777777777' ||
        cnpj == '88888888888888' ||
        cnpj == '99999999999999' ||
        cnpj.length != 14
    ) {
        return false
    }

    // Calculo do 1o. Digito Verificador
    let num = 0
    let sm = 0
    let peso = 2

    for (let i = 11; i >= 0; i--) {
        num = parseInt(cnpj[i].toString())
        sm = sm + num * peso
        peso = peso + 1
        if (peso == 10) {
            peso = 2
        }
    }

    let dig13
    let r = sm % 11
    if (r == 0 || r == 1) {
        dig13 = '0'
    } else {
        dig13 = `${11 - r}`
    }

    // Calculo do 2o. Digito Verificador
    sm = 0
    peso = 2
    for (let i = 12; i >= 0; i--) {
        num = parseInt(cnpj[i].toString())
        sm = sm + num * peso
        peso = peso + 1
        if (peso == 10) {
            peso = 2
        }
    }

    let dig14
    r = sm % 11
    if (r == 0 || r == 1) {
        dig14 = '0'
    } else {
        dig14 = `${11 - r}`
    }

    if (dig13 == cnpj[12] && dig14 == cnpj[13]) return true
    return false
}