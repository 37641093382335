const routes = {
  path: 'tiposervico',
  name: 'TipoServico',
  component: () => import('./TipoServico.vue'),
  children: [
      {
          path: 'inserir',
          name: 'Tipo_Servico_Inserir',
          component: () => import('./TipoServicoForm.vue'),
          meta: {
            rules: ['SOL-001'],
        }
      },
      {
          path: ':id/atualizar',
          name: 'Tipo_Servico_Atualizar',
          component: () => import('./TipoServicoForm.vue'),
          meta: {
            rules: ['SOL-001'],
        }
      },
  ],
  meta: {
    rules: ['SOL-001'],
}
};

export default routes;
