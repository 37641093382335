export const routes = {
    path: 'demandasexpress',
    name: 'CRM_Checkout_DemandasExpress',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['CRM-CHECKOUT-DEMANDASEXPRESS-00'],
    },
    children: [
        {
            path: ':id',
            name: 'CRM_Checkout_DemandasExpress_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['CRM-CHECKOUT-DEMANDASEXPRESS-00'],
            },
        },
    ],
};
