const menuFinanceiro = {
    label: 'Convênios de Execução Direta',
    icon: 'handshake',
    to: '/rm/convenios/execucaodireta',
    rules: ['RM-CONV-EXEC-DIRETA-00'],
};

const menuGestor = {
    label: 'Conv. Exec. Direta Gestor',
    icon: 'handshake',
    to: '/rm/convenios/execucaodireta/gestor',
    rules: ['RM-CONV-EXEC-DIRETA-GESTOR'],
};

export { menuFinanceiro, menuGestor };
