const routes = {
    path: 'parceria',
    name: 'MapaParceiros_Parceria',
    meta: {
        rules: ['MAPAPARC-PES-00'],
    },
    children: [
        {
            path: '',
            name: 'MapaParceiros_Parceria_Listagem',
            component: () => import('./lista/ListaParceria.vue'),
        },
        {
            path: 'detalhar/:id',
            name: 'MapaParceiros_Parceria_Detalhar',
            component: () => import('./detalhes/DetalhesParceria.vue'),
        },
        {
            path: 'form',
            children: [
                {
                    path: 'geral/:id?',
                    name: 'MapaParceiros_Parceria_Formulario_DadosGerais',
                    component: () => import('./cadastro/cadastro-parceria/DadosGeraisEtapa.vue'),
                },
                {
                    path: 'clausulas/:id?',
                    name: 'MapaParceiros_Parceria_Formulario_Clausulas',
                    component: () => import('./cadastro/cadastro-parceria/ClausulasEtapa.vue'),
                },
                {
                    path: 'dados-contrato/:id?',
                    name: 'MapaParceiros_Parceria_Formulario_DadosContrato',
                    component: () => import('./cadastro/cadastro-parceria/DadosContratoEtapa.vue'),
                },
                {
                    path: 'valores-perfis/:id?',
                    name: 'MapaParceiros_Parceria_Formulario_ValoresPerfis',
                    component: () => import('./cadastro/cadastro-parceria/ValoresPerfisEtapa.vue'),
                },
            ],
        },
    ],
};

export default routes;