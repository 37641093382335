import { routes as contasRotas } from './contas/rotas.js';
import { routes as pixRotas } from './pix/rotas.js';

const routes = {
    path: 'pagamentos',
    name: 'Pagamentos',
    component: () => import('./Pagamentos.vue'),
    children: [
        contasRotas,
        pixRotas
    ],
    meta: {
        rules: [
            ...contasRotas.meta.rules,
            ...pixRotas.meta.rules
        ],
    },
};

export default routes;
