import { routes as atendimentosRotas } from './atendimentos/rotas.js';
import { routes as parceiropjRotas } from './parceiropj/rotas.js';
// import { routes as parceiropfRotas } from './parceiropf/rotas.js';
import { rotaAlterarPf as rotaAlterarPf } from './parceiropf/rotaAlterarPf.js';

const routes = {
    path: 'nan',
    name: 'NAN',
    component: () => import('./NAN.vue'),
    children: [
        atendimentosRotas,
        parceiropjRotas,
        // parceiropfRotas,
        rotaAlterarPf

    ],
    meta: {
        rules: [
            ...atendimentosRotas.meta.rules,
            ...parceiropjRotas.meta.rules,
            // ...parceiropfRotas.meta.rules,
            ...rotaAlterarPf.meta.rules,
        ],
    },
};

export default routes;
