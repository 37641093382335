const routes = {
  path: 'tipocertificado',
  name: 'TipoCertificado',
  component: () => import('./TipoCertificado.vue'),
  children: [
    {
      path: 'inserir',
      name: 'Tipo_Certificado_Inserir',
      component: () => import('./TipoCertificadoForm.vue'),
      meta: {
        rules: ['SOL-001'],
    }
    },
    {
      path: ':id/atualizar',
      name: 'Tipo_Certificado_Atualizar',
      component: () => import('./TipoCertificadoForm.vue'),
      meta: {
        rules: ['SOL-001'],
    }
    },
  ],
  meta: {
    rules: ['SOL-001'],
}
}

export default routes;