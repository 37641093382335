import controleAcessoMenu from './controleacesso/menu.js';
import { administracaoMenu } from './administracao/menu.js';
import rdStationMenu from './rdstation/menu.js';
import expressosMenu from './expressos/menu.js';
import mineradorMenu from './minerador/menu.js';
import gestaoEmailMenu from './gestaoemails/menu.js';
import mapaParceirosMenu from './mapaparceiros/menu.js';
import pagamentosMenu from './pagamentos/menu.js';
import agenciaConceitoMenu from './agenciaconceito/menu.js';
import rmMenu from './rm/menu.js';
import checkins from './checkins/menu.js';
import { crmMenu } from './crm/menu.js';
import { brasilMaisMenu } from './brasilmais/menu.js';
import solMenu from './sol/menu';
import utilidadesMenu from './utilidades/menu.js';
import senacMenu from './senac/menu.js';
import { nanMenu } from './nan/menu.js';
import menuVendas from './vendas/menu.js';

const menu = [
    rdStationMenu,
    mineradorMenu,
    expressosMenu,
    controleAcessoMenu,
    administracaoMenu,
    gestaoEmailMenu,
    pagamentosMenu,
    mapaParceirosMenu,
    agenciaConceitoMenu,
    rmMenu,
    crmMenu,
    checkins,
    brasilMaisMenu,
    utilidadesMenu,
    senacMenu,
    solMenu,
    nanMenu,
    menuVendas,
];

export default menu;
