import { menu as contasMenu } from './contas/menu';
import { menu as pixMenu } from './pix/menu';

const pagamentosMenu = {
    label: 'Pagamentos',
    icon: 'pi pi-fw pi-money-bill',
    rules: [...pixMenu.rules, ...contasMenu.rules],
    items: [pixMenu, contasMenu],
};

export default pagamentosMenu
