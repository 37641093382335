<template>
    <label :class='getLabelClass()' :for='id'>{{ label }}</label>
    <div class='p-inputgroup'>
        <InputMask
            :id='id'
            :class='getInputClass()'
            :disabled='desabilita'
            :mask='mask'
            :modelValue='modelValue'
            :unmask='unmask'
            v-bind='$attrs'
            @blur='blur'
            @update:modelValue='updateValue'
        />
        <!--            :value='modelValue'-->
    </div>
    <small v-if='temErro' class='p-error'>{{ this.mensagemErro || '&nbsp;' }}</small>
</template>
<script>
export default {
    inheritAttrs: false,
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: false,
        },
        inputType: {
            type: String,
            default: 'text',
        },
        desabilita: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: [String, Number],
            default: '',
        },
        mensagemErro: {
            type: String,
            default: '',
        },
        temErro: {
            type: Boolean,
            default: false,
        },
        inputRef: {
            type: Function,
            default: () => {
            },
        },
        mask: {
            type: String,
            default: '',
        },
        unmask: {
            type: Boolean,
            default: false,
        },
        blur: {
            type: Function,
            default: () => {
            },
        },
    },
    methods: {
        updateValue(value) {
            this.$emit('update:modelValue', value);
        },
        getInputClass() {
            return {
                'p-invalid': this.temErro,
            };
        },
        getLabelClass() {
            return {
                'error-field-label': this.temErro,
                'required': this.required,
            };
        },
    },
    watch: {
        modelValue() {
            this.$emit('touched', true);
        },
    },
};
</script>
