const routes = {
    path: 'origemcontratacao',
    name: 'OrigemContratacao',
    component: () => import('./OrigemContratacao.vue'),
    children: [
        {
            path: 'inserir',
            name: 'Origem_Contratacao_Inserir',
            component: () => import('./OrigemContratacaoForm.vue'),
            meta: {
                rules: ['SOL-001'],
            }
        },
        {
            path: ':id/atualizar',
            name: 'Origem_Contratacao_Atualizar',
            component: () => import('./OrigemContratacaoForm.vue'),
            meta: {
                rules: ['SOL-001'],
            }
        },
    ],
    meta: {
        rules: ['SOL-001'],
    }
};

export default routes;
