<template>
    <label :for="id" :class="getLabelClass()">{{ label }}</label>
    <div class="p-inputgroup">
        <InputText
            :id='id'
            :class='getInputClass()'
            :disabled='desabilita'
            :type='inputType'
            :value='modelValue'
            v-bind='$attrs'
            @input='updateValue'
        />
    </div>
    <small class="p-error" v-if="temErro">{{ this.mensagemErro || '&nbsp;' }}</small>
</template>
<script>
export default {
    inheritAttrs: false,
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: false,
        },
        inputType: {
            type: String,
            default: 'text',
        },
        desabilita: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: [String, Number],
            default: '',
        },
        mensagemErro: {
            type: String,
            default: '',
        },
        temErro: {
            type: Boolean,
            default: false,
        },
        inputRef: {
            type: Function,
            default: () => {},
        },
    },
    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        getInputClass() {
            return {
                'p-invalid': this.temErro,
            };
        },
        getLabelClass() {
            return {
                'error-field-label': this.temErro,
                'required': this.required,
            };
        },
    },
    watch: {
        modelValue() {
            this.$emit('touched', true);
        },
    },
};
</script>
