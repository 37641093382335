const routes = {
  path: 'vitrine',
  name: 'Vitrine',
  component: () => import('./Vitrine.vue'),
  children: [
    {
        path: 'busca',
        name: 'Vitrine_Busca_Avancada',
        component: () => import('./ListagemBuscaAvancada.vue'),
        meta: {
          rules: ['SOL-000'],
      }
    },
],
  meta: {
    rules: ['SOL-000'],
}
};

export default routes;
