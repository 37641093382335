import { routes as qrCodeRotas } from './qrcode/rotas.js';

export const routes = {
    path: 'checkins',
    name: 'AgenciaConceito_CheckIns',
    component: () => import('./CheckIns.vue'),
    children: [
        qrCodeRotas,
        {
            path: 'listar',
            name: 'AgenciaConceito_CheckIns_Listar',
            component: () => import('./Listagem.vue'),
            meta: {
                rules: ['AGECON-CHECKIN-00'],
            },
        },
        {
            path: ':id/detalhar',
            name: 'AgenciaConceito_CheckIns_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['AGECON-CHECKIN-01'],
            },
        },
    ],
    meta: {
        rules: [
            ...qrCodeRotas.meta.rules,
        ],
    },
};
