const routes = {
  path: 'tipoatendimento',
  name: 'TipoAtendimento',
  component: () => import('./TipoAtendimento.vue'),
  children: [
      {
          path: 'inserir',
          name: 'Tipo_Atendimento_Inserir',
          component: () => import('./TipoAtendimentoForm.vue'),
          meta: {
            rules: ['SOL-001'],
        }
      },
      {
          path: ':id/atualizar',
          name: 'Tipo_Atendimento_Atualizar',
          component: () => import('./TipoAtendimentoForm.vue'),
          meta: {
            rules: ['SOL-001'],
        }
      },
  ],
  meta: {
    rules: ['SOL-001'],
}
};

export default routes;