import { routes as demandasExpressRotas } from './demandasexpress/rotas.js';
import { routes as carrinhosRotas } from './carrinhos/rotas.js';

export const routes = {
    path: 'checkout',
    name: 'CRM_Checkout',
    component: () => import('./Checkout.vue'),
    children: [
        demandasExpressRotas, carrinhosRotas
    ],
    meta: {
        rules: [
            ...demandasExpressRotas.meta.rules, ...carrinhosRotas.meta.rules
        ],
    },
};
