<template>
    <div class="layout-footer flex jc-between">
        <div>Portal de Serviços - SEBRAE-PE</div>
        <div>
            <strong>v{{ versao }}</strong>
        </div>
        <div>
            <span class="mr-1">Powered by</span>
            <a href="https://www.bluetechnology.com.br/"> Blue</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppFooter',
    data() {
        return {
            versao: process.env.VUE_APP_VERSION,
        };
    },
};
</script>

<style scoped></style>
