<template>
    <Tag :class="`status-${status?.textoNormalizado().replace(/\s+/g, '')}`">{{ status }}</Tag>
</template>

<script>
export default {
    props: {
        status: {
            type: String,
        },
    },

    data() {
        return {};
    },

    computed: {},
};
</script>
