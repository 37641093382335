const LinksPagamentosRoutes = {
    path: '/vendas/linkspagamentos',
    name: 'links-pagamentos',
    component: () => import('./ListaLinksPagamentos.vue'),
    meta: {
        rules: ['CHECKOUTS-PAGAMENTOS-00'],
    },
    children: [
        {
            path: ':id',
            name: 'links-pagamentos-detalhes',
            component: () => import('./DetalhesLinkPagamento.vue'),
            meta: {
                rules: ['CHECKOUTS-PAGAMENTOS-02'],
            },
        },
    ],
};

export default LinksPagamentosRoutes;
