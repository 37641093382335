import publicoAlvoMenu from './publicoalvo/menu';
import setorEconomicoMenu from './setoreconomico/menu';
import origemContratacaoMenu from './origemcontratacao/menu';
// import tipoAtendimentoMenu from './tipoatendimento/menu';
import macrossegmentoEconomicoMenu from './macrossegmentoeconomico/menu';
import naturezasMenu from './naturezas/menu';
import instrumentosMenu from './instrumentos/menu';
import areaMenu from './area/menu';
import subAreaMenu from './subarea/menu';
import contratacaoCredenciadoMenu from './contratacaocredenciado/menu';
//import personasMenu from './personas/menu';
import politicaPrecoMenu from './politicapreco/menu';
//import doresMenu from './dores/menu';
import modalidadeTipoServicoMenu from './modalidadetiposervico/menu';
import tipoServicoMenu from './tiposervico/menu';
import complexidadeMenu from './complexidade/menu';
import complexidadeMetodologiaMenu from './complexidademetodologia/menu';
import tipoCertificadoMenu from './tipocertificado/menu';
import metodologiaMenu from './metodologia/menu';
import solucaoMenu from './solucao/menu';
import vitrineMenu from './vitrine/menu';
import agrupadorMenu from './agrupador/menu';
import controleAcessoMenuSol from './controleacesso/menu';
import chamadoMenu from './chamado/menu';
import relatorioMenu from './relatorio/menu';

const menu = {
    label: 'Sol',
    icon: 'pi pi-sun',
    rules: [
        ...controleAcessoMenuSol.rules,
        ...complexidadeMenu.rules,
        ...complexidadeMetodologiaMenu.rules,
        ...tipoCertificadoMenu.rules,
        ...metodologiaMenu.rules,
        ...publicoAlvoMenu.rules,
        ...setorEconomicoMenu.rules,
        ...origemContratacaoMenu.rules,
        // ...tipoAtendimentoMenu.rules,
        ...macrossegmentoEconomicoMenu.rules,
        ...naturezasMenu.rules,
        // ...instrumentosMenu.rules,
        ...areaMenu.rules,
        ...subAreaMenu.rules,
        ...contratacaoCredenciadoMenu.rules,
        //...personasMenu.rules,
        ...politicaPrecoMenu.rules,
        //...doresMenu.rules,
        ...modalidadeTipoServicoMenu.rules,
        ...tipoServicoMenu.rules,
        ...solucaoMenu.rules,
        ...vitrineMenu.rules,
        ...agrupadorMenu.rules,
        ...relatorioMenu.rules,
    ],
    items: [
        controleAcessoMenuSol,
        {
            label: 'Cadastros',
            icon: 'pi pi-pencil',
            rules: ['SOL-001'],
            items: [
                solucaoMenu,
                {
                    label: 'Sub-Cadastros',
                    icon: 'pi pi-bars',
                    items: [
                        agrupadorMenu,
                        areaMenu,
                        complexidadeMenu,
                        complexidadeMetodologiaMenu,
                        contratacaoCredenciadoMenu,
                        //doresMenu,
                        instrumentosMenu,
                        //tipoServicoMenu,
                        // instrumentosMenu,
                        macrossegmentoEconomicoMenu,
                        metodologiaMenu,
                        // naturezasMenu,
                        origemContratacaoMenu,
                        //personasMenu,
                        politicaPrecoMenu,
                        publicoAlvoMenu,
                        setorEconomicoMenu,
                        subAreaMenu,
                        modalidadeTipoServicoMenu,
                        // tipoAtendimentoMenu,
                        tipoCertificadoMenu,
                    ],
                },
            ],
        },
        chamadoMenu,
        vitrineMenu,
        relatorioMenu,
    ],
};

export default menu;
