import produtosRoutes from './produtos/rotas';
import linksPagamentosRoutes from './linkspagamentos/rotas';

const routes = {
    path: 'vendas',
    name: 'Vendas',
    component: () => import('./Vendas.vue'),
    meta: {
        rules: [...produtosRoutes.meta.rules, ...linksPagamentosRoutes.meta.rules],
    },
    children: [produtosRoutes, linksPagamentosRoutes],
};

export default routes;
