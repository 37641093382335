import publicoAlvoRoute from './publicoalvo/rotas';
import setorEconomicoRoute from './setoreconomico/rotas';
import origemContratacaoRoute from './origemcontratacao/rotas';
import tipoAtendimentoRoute from './tipoatendimento/rotas';
import macrossegmentoEconomicoRoute from './macrossegmentoeconomico/rotas';
import naturezasRoute from './naturezas/rotas';
import instrumentosRoute from './instrumentos/rotas';
import areaRoute from './area/rotas';
import subAreaRoute from './subarea/rotas';
import contratacaoCredenciadoRoute from './contratacaocredenciado/rotas';
import personaRoute from './personas/rotas';
import politicaPreco from './politicapreco/rotas';
import doresRoute from './dores/rotas';
import modalidadeTipoServicoRoute from './modalidadetiposervico/rotas';
import tipoServicoRoute from './tiposervico/rotas';
import complexidadeRoute from './complexidade/rotas';
import complexidadeMetodologiaRoute from './complexidademetodologia/rotas';
import tipoCertificadoRoute from './tipocertificado/rotas';
import metodologiaRoute from './metodologia/rotas';
import solucaoRoute from './solucao/rotas';
import vitrineRoute from './vitrine/rotas';
import agrupadorRoute from './agrupador/rotas';
import controleAcessoSol from './controleacesso/rotas';
import chamadoRoute from './chamado/rotas';
import relatorioRoute from './relatorio/rotas';

const routes = {
    path: 'sol',
    name: 'Sol',
    component: () => import('./Sol.vue'),
    meta: {
        rules: [
            ...controleAcessoSol.meta.rules,
            ...publicoAlvoRoute.meta.rules,
            ...setorEconomicoRoute.meta.rules,
            ...origemContratacaoRoute.meta.rules,
            ...tipoAtendimentoRoute.meta.rules,
            ...macrossegmentoEconomicoRoute.meta.rules,
            ...naturezasRoute.meta.rules,
            ...instrumentosRoute.meta.rules,
            ...areaRoute.meta.rules,
            ...subAreaRoute.meta.rules,
            ...contratacaoCredenciadoRoute.meta.rules,
            ...personaRoute.meta.rules,
            ...politicaPreco.meta.rules,
            ...doresRoute.meta.rules,
            ...modalidadeTipoServicoRoute.meta.rules,
            ...tipoServicoRoute.meta.rules,
            ...complexidadeRoute.meta.rules,
            ...complexidadeMetodologiaRoute.meta.rules,
            ...tipoCertificadoRoute.meta.rules,
            ...metodologiaRoute.meta.rules,
            ...solucaoRoute.meta.rules,
            ...vitrineRoute.meta.rules,
            ...agrupadorRoute.meta.rules,
            ...chamadoRoute.meta.rules,
            ...relatorioRoute.meta.rules,
        ],
    },
    children: [
        controleAcessoSol,
        agrupadorRoute,
        publicoAlvoRoute,
        setorEconomicoRoute,
        origemContratacaoRoute,
        tipoAtendimentoRoute,
        macrossegmentoEconomicoRoute,
        // naturezasRoute,
        instrumentosRoute,
        areaRoute,
        subAreaRoute,
        contratacaoCredenciadoRoute,
        personaRoute,
        politicaPreco,
        doresRoute,
        modalidadeTipoServicoRoute,
        tipoServicoRoute,
        complexidadeRoute,
        complexidadeMetodologiaRoute,
        tipoCertificadoRoute,
        metodologiaRoute,
        solucaoRoute,
        vitrineRoute,
        chamadoRoute,
        relatorioRoute,
    ],
};

export default routes;
