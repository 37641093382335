const routes = {
    path: 'consultorias',
    name: 'Utilidades_Consultorias',
    component: () => import('./Listagem.vue'),
    children: [
        {
            path: ':id',
            name: 'Utilidades_Consultorias_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['UTIL-CONSULTORIAS-02'],
            },
        },
        {
            path: 'inserir',
            name: 'Utilidades_Consultorias_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['UTIL-CONSULTORIAS-01'],
            },
        },
        {
            path: ':id/atualizar',
            name: 'Utilidades_Consultorias_Atualizar',
            component: () => import('./Atualizar.vue'),
            meta: {
                rules: ['UTIL-CONSULTORIAS-03'],
            },
        }
    ],
    meta: {
        rules: ['UTIL-CONSULTORIAS-00', 'UTIL-CONSULTORIAS-01', 'UTIL-CONSULTORIAS-02'],
    },
};

export default routes;
