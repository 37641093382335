import { createRouter, createWebHashHistory } from 'vue-router';

import App from './App.vue';
import { trackRouter } from 'vue-gtag-next';
import store from './store/store';
import { temAcessoView } from './shared/auth/temAcesso';
import credenciamentoRotas from '../src/app/checkins/credenciamento/rotas.js';

import appRotas from './app/rotas.js';
import { rotasChamado, rotasLiberadas } from './app/sol/chamado/centralChamados/rotas.js';
import feiraEmpreendedorRotas from './app/checkins/feiraempreendedor/rotas.js';

const routes = [
    {
        path: '/',
        name: 'home',
        component: App,
        redirect: { name: 'DashBoard' },
        children: [...appRotas],
    },
    credenciamentoRotas,
    // {
    // 	path: '/checkins/credenciamento',
    // 	name: 'checkins_credenciamento',
    // 	component: () => import('./app/checkins/credenciamento/Credenciamento.vue'),
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import('./app/auth/Login.vue'),
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue'),
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue'),
    },
    {
        path: '/compra/:id',
        name: 'compra',
        component: () => import('./app/vendas/checkoutpagamentos/TelaPagamento.vue'),
    },
    {
        path: '/compra/espelhoPedido/:id',
        name: 'espelhoPedido',
        component: () => import('./app/vendas/checkoutpagamentos/EspelhoPedido.vue'),
    },
    {
        path: '/compra/linkExpirado/:id',
        name: 'linkExpirado',
        component: () => import('./app/vendas/checkoutpagamentos/linkExpirado.vue'),
    },
    ...rotasChamado,
    ...feiraEmpreendedorRotas,
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

trackRouter(router);

router.beforeEach((to, from, next) => {
    const publicPages = [
        '/login',
        '/error',
        '/atualizarsenha',
        '/recuperarsenha',
        '/sol/chamado/centralChamados',
        ...rotasLiberadas,
        '/checkins/feiraempreendedor',
        '/checkins/feiraempreendedor/totem',
        '/compra/:id',
        '/compra/espelhoPedido/:id',
        '/compra/linkExpirado/:id',
    ];
    const publicRotes = ['CRM_Checkout_Carrinhos_Pagamento', 'BrasilMais_Certificados', 'compra', 'espelhoPedido', 'linkExpirado'];
    const authRequired = !publicPages.includes(to.path) && !publicRotes.includes(to.name);

    if (!authRequired) {
        next();
    } else {
        const loggedIn = store.getters.isAutenticado;
        if (authRequired && !loggedIn) {
            store.dispatch('tryAutoLogin');
            if (store.getters.isAutenticado) {
                if (temAcessoView(to.meta.rules)) {
                    next();
                } else {
                    next('/access');
                }
            } else {
                next({
                    path: '/login',
                    query: {
                        redirect: to.path,
                        ...to.query,
                    },
                });
            }
        } else {
            if (temAcessoView(to.meta.rules)) {
                next();
            } else {
                next('/access');
            }
        }
    }
});

export default router;
