export const routes = {
    path: 'participantes',
    name: 'BrasilMais_Participantes',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['BRASILMAIS-00'],
    },
    children: [
        {
            path: 'inserir',
            name: 'BrasilMais_Participantes_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['BRASILMAIS-01'],
            },
        },
    ],
};