const routes = {
    path: 'regras',
    name: 'Regras',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['CTRLACS-RA-00'],
    },
};

export default routes;
