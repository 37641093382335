const routes = {
  path: 'publicoalvo',
  name: 'PublicoAlvo',
  component: () => import('./PublicoAlvo.vue'),
  children: [
    {
      path: 'inserir',
      name: 'Publico_Alvo_Inserir',
      component: () => import('./PublicoAlvoForm.vue'),
      meta: {
        rules: ['SOL-001'],
    }
    },
    {
      path: ':id/atualizar',
      name: 'Publico_Alvo_Atualizar',
      component: () => import('./PublicoAlvoForm.vue'),
      meta: {
        rules: ['SOL-001'],
    }
    },
  ],
  meta: {
    rules: ['SOL-001'],
}
}

export default routes;