import leadsMenu from './leads/menu';
import parametrosMenu from './parametros/menu';

const menu = {
    label: 'RdStation',
    icon: 'pi pi-fw pi-list',
    rules: [...leadsMenu.rules, ...parametrosMenu.rules],
    items: [leadsMenu, parametrosMenu],
};

export default menu;
