<template>
    <loading-block-ui v-if="$store.getters.isLoading"></loading-block-ui>
    <Toast :baseZIndex="1000" />
    <ConfirmDialog></ConfirmDialog>
    <router-view></router-view>
</template>

<script>
export default {
    methods: {
        async obterConfig() {
            await this.$store.dispatch('carregarConfigApp');
        },
    },

    async beforeCreate() {
        await this.$store.dispatch('verificarVersao');
    },

    async mounted() {
        await this.obterConfig();
    },
};
</script>
